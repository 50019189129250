import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import ContentBox from "../../components/ContentBox/ContentBox";
import Button from "../../components/Button/Button";
import { ServicesContainer, ServicesDetailsText, ImgContainer } from "./ServicesDetails.style";

const ServicesDetails = ({ serviceData }) => {
  const location = useLocation();
  const history = useHistory();
  const goBack = () => history.push("/services#gallery");
  return (
    <ServicesContainer>
      <Button onClick={goBack} text="< Go back to services" goBackBtn />
      <h2>HOW IT WORKS</h2>
      <ServicesDetailsText beforeColor="fundamentals">
        <p>
          {serviceData.introText.map((text) => (
            <span>{text}</span>
          ))}
        </p>
      </ServicesDetailsText>
      <ContentBox
        sectionName={serviceData.contentBox.sectionName}
        sectionText={serviceData.contentBox.para}
        img={serviceData.contentBox.img}
        smallMarge
        rightDisplay
        noBefore
        withHalo
      />
      <ServicesDetailsText beforeColor="athlectic">
        {location.pathname.includes("personal") && <h2>30’ Coaching Session</h2>}
        <p>{serviceData.extraInfos.text}</p>
      </ServicesDetailsText>
      <ImgContainer src={serviceData.extraInfos.largeImg} alt="service image" />
    </ServicesContainer>
  );
};

export default ServicesDetails;
