import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Gallery from "../../components/Gallery/Gallery";
import { ServicesData } from "./ServicesData";
import { ServicesContainer } from "./Services.style";

const Services = () => {
  const history = useHistory();
  useEffect(() => {
    const selected = history.location.hash;
    if (selected && selected.length > 0) {
      const elem = document.querySelector(selected);
      elem && elem.scrollIntoView();
    }
  }, []);
  return (
    <ServicesContainer>
      <h2>CHOOSE YOUR GOALS/NEEDS</h2>
      <p>
        <span>Everybody has to figure out what drives them… WHAT DRIVES YOU ?!</span>
        <span>Choose below how you’d like to be coached</span>
        <span>so we can tailor a unique plan based on your needs !</span>
      </p>
      <Gallery id="gallery" GalleryContent={ServicesData.galleryContent} />
    </ServicesContainer>
  );
};

export default Services;
