import React, { useState, useEffect } from "react";
import useScreenType from "react-screentype-hook";
import { useLocation } from "react-router-dom";
import { HeaderContainer, HeaderTextContent } from "./Header.style.js";
import HeaderMenu from "./HeaderMenu/HeaderMenu";
import { HeaderData } from "./HeaderData";
import Button from "../Button/Button";
import FlexBox from "../FlexBox/FlexBox";

const Header = ({ locationChanges, setLocationChanges }) => {
  const [currentLocation, setCurrentLocation] = useState("");
  const location = useLocation();
  const screenType = useScreenType();
  const headerContainerRef = React.useRef();

  const checkCurrentContent = (location) =>
    location.includes("/home")
      ? HeaderData.home
      : location === "/services"
      ? HeaderData.services
      : location.includes("/personal-training")
      ? HeaderData.services.personalTraining
      : location.includes("/athlete-training")
      ? HeaderData.services.athleteTraining
      : location.includes("/group-training")
      ? HeaderData.services.groupTraining
      : location.includes("/boxing-training")
      ? HeaderData.services.boxingTraining
      : location.includes("/about-us")
      ? HeaderData.about
      : location.includes("/contact")
      ? HeaderData.contact
      : "";

  useEffect(() => {
    setCurrentLocation(checkCurrentContent(location.pathname));
  }, [location]);
  useEffect(() => {
    setTimeout(() => {
      if (locationChanges) {
        setLocationChanges(false);
      }
    }, 1000);
  }, [locationChanges]);

  return (
    <HeaderContainer location={location.pathname} locationChanges={locationChanges} ref={headerContainerRef}>
      {HeaderData.headerBackgroundContent.map(({ background, mobileBackground, path }) => (
        <img
          className={location.pathname.includes(path) ? "active" : "hide"}
          src={screenType.isMobile ? mobileBackground : background}
          alt="header background"
        />
      ))}
      <HeaderMenu />
      <HeaderTextContent location={location.pathname}>
        <FlexBox justify="center" align="center" direction="column">
          <h1>{currentLocation.title}</h1>
          {location.pathname === "/home" && (
            <React.Fragment>
              <h2>
                YOU REACH YOUR <span>PRIME</span>
              </h2>
              <Button link="https://booking.appointy.com/Prymeset" text="Book a session" target="_blank" />
            </React.Fragment>
          )}
        </FlexBox>
      </HeaderTextContent>
    </HeaderContainer>
  );
};

export default Header;
