import React from "react";
import { FooterContainer, MainContentContainer, LocationContainer, LogoContainer, BookingSessionContainer, TermsContainer } from "./Footer.style.js";
import Button from "../Button/Button";
import SocialMedia from "../SocialMedia/SocialMedia";
import WhiteLogo from "../../assets/images/Footer/Footer_logo.png";
import Terms from "./Conditions_Prymeset.pdf";

const Footer = () => {
  return (
    <FooterContainer direction="column">
      <MainContentContainer direction="row" justify="space-evenly" align="center">
        <LocationContainer direction="column" align="center">
          <p>
            <span>Our Location</span>
          </p>
          <p>
            <strong>Brussels, BE</strong>
          </p>
        </LocationContainer>
        <LogoContainer direction="column" align="center">
          <img src={WhiteLogo} alt="Prymeset white logo" />
          <p>
            <span>prymeset@gmail.com</span>
          </p>
          <SocialMedia />
        </LogoContainer>
        <BookingSessionContainer direction="column" align="center">
          <p>
            <span>Not sure about it ?</span>
          </p>
          <Button link="https://booking.appointy.com/Prymeset" text="Book a session" target="_blank" />
        </BookingSessionContainer>
      </MainContentContainer>
      <TermsContainer justify="space-between">
        <a href={Terms} target="_blank">
          Terms and Conditions
        </a>
        <span>&copy; 2021 prymeset</span>
      </TermsContainer>
    </FooterContainer>
  );
};

export default Footer;
