import Contact from "../../assets/images/Contact/Contact.png";
export const ContactData = {
  bookingSessionContent: [
    "Book a session with me ! It is important for me to establish an initial contact with individuals who are looking to reach their athletic prime .",
    "We’ll have the opportunity to meet and we’ll show you how we can work together based on your goals and preferences.",
  ],
  formContent: {
    form: {
      title: "session form",
      inputs: [
        {
          title: "name",
          placeholder: "name",
          type: "text",
          required: true,
        },
        {
          title: "email",
          placeholder: "email",
          type: "email",
          required: true,
        },
        {
          title: "course",
          placeholder: "select a course",
          type: "select",
          required: true,
        },
        {
          title: "phone",
          placeholder: "phone",
          type: "text",
          required: false,
        },
        {
          title: "message",
          placeholder: "message",
          type: "textarea",
          required: false,
        },
        {
          title: "input",
          placeholder: "send a message",
          type: "submit",
          required: false,
        },
      ],
      img: Contact,
    },
  },
};
